import React from 'react';
import { navigate, Link } from 'gatsby';
import { requestPasswordRecovery, isLoggedIn } from '../services/auth';
import { Helmet } from 'react-helmet';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import { IoMdHelpBuoy } from 'react-icons/io';
import envoyer from '../img/envoyer.svg';
import patientez from '../img/patientez.svg';
import styles from './forgot-password.module.css';
class Login extends React.Component {
  state = {
    isLoading: false,
    email: ``,
    response: {
      type: ``,
      message: ``
    }
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  translateMessage = message => {
    if (message === 'User not found') {
      return `L'adresse email ne correspond à aucun compte`;
    } else {
      return message;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    requestPasswordRecovery(this.state.email)
      .then(response => {
        this.setState({
          response: {
            ...this.state.response,
            type: `success`,
            message: `Un email de réinitialisation vous a été envoyé. Vous pouvez fermer cette page.`
          }
        });
      })
      .catch(error => {
        this.setState({
          response: {
            ...this.state.response,
            type: `error`,
            message: this.translateMessage(error.message)
          }
        });
        console.log(this.state);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (isLoggedIn()) {
      navigate(`/app/profile`);
    }

    return (
      <>
        <Helmet title="Mot de passe oublié" />
        <div className={styles.container}>
          <div className={styles.subcontainer}>
            <h1 style={{ marginBottom: 0 }}>
              <IoMdHelpBuoy />
            </h1>
            <h1 className="uppercase">Mot de passe oublié ?</h1>
            <p className={this.state.response.type === 'error' ? 'text-red' : 'text-green'}>
              {this.state.response.message}
            </p>
            <form
              id="loginForm"
              className="w-full"
              method="post"
              onSubmit={event => {
                this.handleSubmit(event);
              }}
            >
              <FormGroup>
                <TextField
                  label="Email *"
                  placeholder="Email"
                  InputProps={{
                    type: 'email',
                    required: true,
                    name: 'email',
                    onChange: this.handleUpdate,
                    value: this.state.username
                  }}
                />
              </FormGroup>
            </form>
            <button form="loginForm" disabled={this.state.isLoading} type="submit">
              {this.state.isLoading ? (
                <img src={patientez} alt="Patientez" style={{ verticalAlign: 'top', marginBottom: 0 }} />
              ) : (
                <img src={envoyer} alt="Envoyer" style={{ verticalAlign: 'top', marginBottom: 0 }} />
              )}
            </button>

            <div className="flex mt-10 w-full">
              <Link className="text-blue" to="/app/signup">
                Créer un nouveau compte
              </Link>
              <Link className="text-blue ml-5" to="/app/login">
                Se connecter
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
