import React from 'react';
import styles from './profile-subscription.module.css';

export default props => {
  return (
    <div className={styles.container}>
      <div className={styles.third1}>
        <h3>One</h3>
      </div>
      <div className={styles.third2}>
        <h3>Two</h3>
      </div>
      <div className={styles.third3}>
        <h3>Three</h3>
      </div>
    </div>
  );
};
