import React from 'react';
import { navigate, Link } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import { signup, login, isLoggedIn } from '../services/auth';
import { Helmet } from 'react-helmet';
import inscription from '../img/inscription.svg';
import patientez from '../img/patientez.svg';
import styles from './signup.module.css';
import { FaRegAddressCard } from 'react-icons/fa';

class Signup extends React.Component {
  state = {
    username: ``,
    password: ``,
    data: {
      firstname: ``,
      lastname: ``,
      biskyLetter: false
    },
    isLoading: false,
    error: ``
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleDataUpdate = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      }
    });
  };

  handleCheckboxClick = () => {
    this.setState({
      data: {
        ...this.state.data,
        biskyLetter: !this.state.data.biskyLetter
      }
    });
  };

  translateMessage = message => {
    if (message === 'A user with this email address has already been registered') {
      return 'Cette adresse email est déjà utilisée';
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    // If any UTM param is found in localStorage, put it in the data
    // alongside with the rest
    const utm = {};
    for (const item of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']) {
      if (localStorage.getItem(item)) {
        utm[item] = localStorage.getItem(item);
      }
    }
    this.setState(
      {
        isLoading: true,
        data: {
          ...this.state.data,
          utm
        }
      },
      () => this.doSignup(this.state)
    );
  };

  doSignup(state) {
    signup(state)
      .then(response => {
        this.setState({ error: `` });
        if (this.state.data.biskyLetter) {
          addToMailchimp(this.state.username, {
            FNAME: this.state.data.firstname,
            LNAME: this.state.data.lastname
          });
        }
        login(this.state)
          .then(response => navigate('/app/profile'))
          .catch(err => {
            this.setState({ error: err.message, isLoading: false });
          });
      })
      .catch(err => {
        this.setState({ error: this.translateMessage(err.message), isLoading: false });
      });
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/app/profile`);
    }

    return (
      <>
        <Helmet title="Inscription" />
        <div className={styles.container}>
          <div className={styles.subcontainer}>
            <h1 style={{ marginBottom: 0 }}>
              <FaRegAddressCard />
            </h1>
            <h1 className="uppercase">miaou !</h1>
            <p className="font-bold text-center">
              Lancez-vous dans l'expérience de personnalisation et offrez à votre chat les délicieuses croquettes bio
              qu'il mérite.
            </p>
            <p className="text-red">{this.state.error}</p>
            <form
              id="loginForm"
              className="w-full"
              method="post"
              onSubmit={event => {
                this.handleSubmit(event);
              }}
            >
              <FormGroup>
                <TextField
                  label="Email *"
                  placeholder="Email"
                  InputProps={{
                    type: 'email',
                    required: true,
                    name: 'username',
                    onChange: this.handleUpdate,
                    value: this.state.username
                  }}
                />
              </FormGroup>

              <FormGroup className="mt-3">
                <TextField
                  label="Prénom *"
                  placeholder="Prénom"
                  InputProps={{
                    type: 'text',
                    required: true,
                    name: 'firstname',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.username
                  }}
                />
              </FormGroup>

              <FormGroup className="mt-3">
                <TextField
                  label="Nom *"
                  placeholder="Nom"
                  InputProps={{
                    type: 'text',
                    required: true,
                    name: 'lastname',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.lastname
                  }}
                />
              </FormGroup>

              <FormGroup className="mt-3">
                <TextField
                  label="Mot de passe *"
                  placeholder="Mot de passe"
                  InputProps={{
                    type: 'password',
                    required: true,
                    name: 'password',
                    onChange: this.handleUpdate,
                    value: this.state.password
                  }}
                />
              </FormGroup>

              <div className="mt-5 flex justify-center items-center">
                <input type="checkbox" checked={this.state.data.biskyLetter} onChange={this.handleCheckboxClick} />
                <label className="ml-5 font-bold" onClick={this.handleCheckboxClick}>
                  Je souhaite recevoir la BiskyLetter
                </label>
              </div>
            </form>
            <button form="loginForm" disabled={this.state.isLoading} type="submit">
              {this.state.isLoading ? (
                <img src={patientez} alt="Patientez" style={{ verticalAlign: 'top', marginBottom: 0 }} />
              ) : (
                <img src={inscription} alt="Inscription" style={{ verticalAlign: 'top', marginBottom: 0 }} />
              )}
            </button>

            <div className="flex mt-10">
              <Link className="text-blue" to="/app/login">
                Vous possédez déjà un compte ?
              </Link>
              <Link className="text-blue ml-5" to="/app/forgot-password">
                Mot de passe oublié?
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Signup;
