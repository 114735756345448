import React from 'react';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import PrivateRoute from '../components/private-route';
import Profile from '../components/profile';
import Login from '../components/login';
import Signup from '../components/signup';
import ForgotPassword from '../components/forgot-password';

class NotFound extends React.Component {
  componentDidMount() {
    navigate('/404');
  }
  render() {
    return null;
  }
}

const App = ({ location }) => (
  <Layout displayFooter={false} location={location}>
    <Router>
      <PrivateRoute path="/app/profile/*" component={Profile} />
      <Login path="/app/login" />
      <Signup path="/app/signup" />
      <ForgotPassword path="/app/forgot-password" />
      <NotFound default />
    </Router>
  </Layout>
);

export default App;
