import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../services/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/app/login` && location.pathname !== `/app/signup`) {
    // If the user is not logged in, redirect to the login page.
    navigate(`/app/login`);
    return null;
  }

  return <Component location={location} {...rest} />;
};

export default PrivateRoute;
