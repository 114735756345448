import React from 'react';
import styles from './profile-cats.module.css';

export default props => {
  return (
    <div className={styles.container}>
      <h1>My Cat</h1>
    </div>
  );
};
