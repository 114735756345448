import React from 'react';
import styles from './profile-details.module.css';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import save from '../img/save.svg';
import patientez from '../img/patientez.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';

import { updateUserCredentials, updateUserData, getCurrentUser } from '../services/auth';

export default class ProfileDetails extends React.Component {
  state = {
    username: ``,
    password: ``,
    data: {
      firstname: ``,
      lastname: ``,
      street: '',
      streetBis: '',
      city: '',
      zipCode: '',
      shippingInstructions: '',
      useShippingAddressForBilling: '',
      billingFullName: '',
      billingStreet: '',
      billingStreetBis: '',
      billingCity: '',
      billingZipCode: '',
      phone: ``
    },
    isLoadingContactInformation: false,
    errorContactInformation: ``,
    successContactInformation: ``,
    isLoadingBillinInformation: false,
    errorBillingInformation: ``,
    successBillingInformation: ``,
    showPassword: false
  };

  constructor(props) {
    super(props);
    this.handleDataUpdate = this.handleDataUpdate.bind(this);
    this.submitInformation = this.submitInformation.bind(this);
    this.submitBillingInformation = this.submitBillingInformation.bind(this);
    this.submitPasswordChange = this.submitPasswordChange.bind(this);
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  componentDidMount() {
    const user = getCurrentUser();
    this.setState({
      username: user.email,
      data: {
        ...this.state.data,
        firstname: user.user_metadata.firstname || '',
        lastname: user.user_metadata.lastname || '',
        phone: user.user_metadata.phone || '',
        street: user.user_metadata.street || '',
        streetBis: user.user_metadata.streetBis || '',
        city: user.user_metadata.streetBis || '',
        zipCode: user.user_metadata.zipCode || '',
        shippingInstructions: user.user_metadata.shippingInstructions || '',
        useShippingAddressForBilling: user.user_metadata.useShippingAddressForBilling || '',
        billingFullName: user.user_metadata.billingFullName || '',
        billingStreet: user.user_metadata.billingStreet || '',
        billingStreetBis: user.user_metadata.billingStreetBis || '',
        billingCity: user.user_metadata.billingCity || '',
        billingZipCode: user.user_metadata.billingZipCode || ''
      }
    });
  }

  submitInformation(event) {
    event.preventDefault();
    this.setState({ isLoadingContactInformation: true, successContactInformation: ``, errorContactInformation: `` });
    updateUserData(this.state.data)
      .then(() => updateUserCredentials({ email: this.state.username }))
      .then(() => this.setState({ successContactInformation: true, errorContactInformation: false }))
      .catch(err => this.setState({ errorContactInformation: true, successContactInformation: false }))
      .finally(() => this.setState({ isLoadingContactInformation: false }));
  }

  submitBillingInformation(event) {
    event.preventDefault();
    this.setState({ isLoadingBillinInformation: true, successBillingInformation: ``, errorBillingInformation: `` });
    updateUserData(this.state.data)
      .then(() => this.setState({ successBillingInformation: true, errorBillingInformation: false }))
      .catch(err => this.setState({ errorBillingInformation: true, successBillingInformation: false }))
      .finally(() => this.setState({ isLoadingBillinInformation: false }));
  }

  submitPasswordChange(event) {
    event.preventDefault();
    this.setState({ isLoadingPasswordChange: true, successPasswordChange: ``, errorPasswordChange: `` });
    updateUserCredentials({ password: this.state.password })
      .then(() => this.setState({ successPasswordChange: true, errorPasswordChange: false }))
      .catch(() => this.setState({ successPasswordChange: false, errorPasswordChange: true }))
      .finally(() => this.setState({ isLoadingPasswordChange: false }));
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handlePasswordChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleDataUpdate = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      }
    });
  };
  handleCheckboxClick = () => {
    this.setState({
      data: {
        ...this.state.data,
        useShippingAddressForBilling: !this.state.data.useShippingAddressForBilling
      }
    });
  };

  translateMessage = message => {
    if (message === 'A user with this email address has already been registered') {
      return 'Cette adresse email est déjà utilisée';
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <h3>Coordonnées</h3>
          <form onSubmit={this.submitInformation} autoComplete="on">
            <FormGroup className="mt-3">
              <TextField
                label="Prénom *"
                placeholder="Prénom"
                InputProps={{
                  type: 'text',
                  required: true,
                  name: 'firstname',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.firstname
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Nom *"
                placeholder="Nom"
                InputProps={{
                  type: 'text',
                  required: true,
                  name: 'lastname',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.lastname
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Email *"
                placeholder="Email"
                InputProps={{
                  type: 'email',
                  required: true,
                  name: 'username',
                  onChange: this.handleUpdate,
                  value: this.state.username
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Téléphone"
                placeholder="Téléphone"
                InputProps={{
                  type: 'tel',
                  required: true,
                  name: 'phone',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.phone
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Adresse postale *"
                placeholder="Adresse postale, boîte postale"
                InputProps={{
                  type: 'text',
                  required: true,
                  name: 'street',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.street
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Complément d'adresse"
                placeholder="Appartement, suite, bloc, bâtiment, etc."
                InputProps={{
                  type: 'text',
                  name: 'streetBis',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.streetBis
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Ville *"
                placeholder="Ville"
                InputProps={{
                  type: 'text',
                  required: true,
                  name: 'city',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.city
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Code Postal *"
                placeholder="Code Postal"
                InputProps={{
                  type: 'text',
                  required: true,
                  name: 'zipCode',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.zipCode
                }}
              />
            </FormGroup>
            <FormGroup className="mt-3">
              <TextField
                label="Instructions pour la livraison"
                placeholder="Avons-nous besoin d'un code de porte d'entrée ou d'un numéro d'interphone?"
                multiline
                rows="3"
                margin="normal"
                InputProps={{
                  name: 'shippingInstructions',
                  onChange: this.handleDataUpdate,
                  value: this.state.data.shippingInstructions
                }}
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.data.useShippingAddressForBilling}
                    onChange={this.handleCheckboxClick}
                  />
                }
                label="Utiliser la même adresse pour la facturation"
              />
            </FormGroup>
            {this.state.successContactInformation ? (
              <p style={{ fontWeight: 'bold', marginBottom: ' ', color: 'var(--bisky-green)' }}>
                Modifications enregistrées ! 🐈
              </p>
            ) : (
              <p>&nbsp;</p>
            )}
            {this.state.errorContactInformation ? (
              <p style={{ fontWeight: 'bold', marginBottom: ' ', color: 'var(--bisky-red)' }}>
                Impossible d'enregistrer les modifications 😼
              </p>
            ) : (
              <p>&nbsp;</p>
            )}
            <button type="submit" disabled={this.state.isLoadingContactInformation}>
              {this.state.isLoadingContactInformation ? (
                <img style={{ margin: 0 }} src={patientez} alt="patientez" />
              ) : (
                <img style={{ margin: 0 }} src={save} alt="enregistrer" />
              )}
            </button>
          </form>
        </div>

        <div className={styles.upperRight}>
          <h3>Adresse de facturation</h3>
          <div
            style={{
              display: this.state.data.useShippingAddressForBilling ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <p>Identique à votre adresse de livraison.</p>
          </div>
          <div style={{ display: this.state.data.useShippingAddressForBilling ? 'none' : null }}>
            <form onSubmit={this.submitBillingInformation}>
              <FormGroup className="mt-3">
                <TextField
                  label="Nom complet *"
                  placeholder="Nom complet"
                  InputProps={{
                    type: 'text',
                    required: true,
                    name: 'billingFullName',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.billingFullName
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <TextField
                  label="Adresse postale *"
                  placeholder="Adresse postale, boîte postale"
                  InputProps={{
                    type: 'text',
                    required: true,
                    name: 'billingStreet',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.billingStreet
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <TextField
                  label="Complément d'adresse"
                  placeholder="Appartement, suite, bloc, bâtiment, etc."
                  InputProps={{
                    type: 'text',
                    name: 'billingStreetBis',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.billingStreetBis
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <TextField
                  label="Ville *"
                  placeholder="Ville"
                  InputProps={{
                    type: 'text',
                    required: true,
                    name: 'billingCity',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.billingCity
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-3 mb-5">
                <TextField
                  label="Code Postal *"
                  placeholder="Code Postal"
                  InputProps={{
                    type: 'text',
                    required: true,
                    name: 'billingZipCode',
                    onChange: this.handleDataUpdate,
                    value: this.state.data.billingZipCode
                  }}
                />
              </FormGroup>
              {this.state.successBillingInformation ? (
                <p style={{ fontWeight: 'bold', marginBottom: ' ', color: 'var(--bisky-green)' }}>
                  Modifications enregistrées ! 🐈
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
              {this.state.errorBillingInformation ? (
                <p style={{ fontWeight: 'bold', marginBottom: ' ', color: 'var(--bisky-red)' }}>
                  Impossible d'enregistrer les modifications 😼
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
              <button type="submit" disabled={this.state.isLoadingBillinInformation}>
                {this.state.isLoadingBillinInformation ? (
                  <img style={{ margin: 0 }} src={patientez} alt="patientez" />
                ) : (
                  <img style={{ margin: 0 }} src={save} alt="enregistrer" />
                )}
              </button>
            </form>
          </div>
        </div>

        <div className={styles.bottomRight}>
          <h3 className="mt-16">Changer de mot de passe</h3>
          <form onSubmit={this.submitPasswordChange} autoComplete="on">
            <FormControl className="mt-3">
              <InputLabel htmlFor="adornment-password">Mot de passe</InputLabel>
              <Input
                required
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handlePasswordChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {this.state.successPasswordChange ? (
              <p style={{ marginTop: '10px', fontWeight: 'bold', marginBottom: ' ', color: 'var(--bisky-green)' }}>
                Modifications enregistrées ! 🐈
              </p>
            ) : (
              <p>&nbsp;</p>
            )}
            {this.state.errorPasswordChange ? (
              <p style={{ fontWeight: 'bold', marginBottom: ' ', color: 'var(--bisky-red)' }}>
                Impossible d'enregistrer les modifications 😼
              </p>
            ) : (
              <p>&nbsp;</p>
            )}
            <button type="submit" disabled={this.state.isLoadingPasswordChange}>
              {this.state.isLoadingPasswordChange ? (
                <img style={{ margin: 0 }} src={patientez} alt="patientez" />
              ) : (
                <img style={{ margin: 0 }} src={save} alt="enregistrer" />
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }
}
