import React from 'react';
import { navigate, Link } from 'gatsby';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import { login, isLoggedIn } from '../services/auth';
import { Helmet } from 'react-helmet';
import styles from './login.module.css';
import connexion from '../img/connexion.svg';
import patientez from '../img/patientez.svg';
import { FaUserCircle } from 'react-icons/fa';

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    isLoading: false,
    error: ``
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleDataUpdate = event => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value
      }
    });
  };

  handleCheckboxClick = () => {
    console.log(!this.state.biskyLetter);
    this.setState({
      data: {
        ...this.state.data,
        biskyLetter: !this.state.biskyLetter
      }
    });
  };

  translateLoginErrors = message => {
    if (message === 'invalid_grant: No user found with this email') {
      return `L'adresse email ne correspond à aucun compte`;
    } else if (message === 'invalid_grant: Invalid Password') {
      return `Mot de passe invalide`;
    } else {
      return message;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    login(this.state)
      .then(response => {
        this.setState({ error: `` });
        navigate('/app/profile');
      })
      .catch(err => {
        console.error('err', err);
        this.setState({ error: this.translateLoginErrors(err.message) });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (isLoggedIn()) {
      navigate(`/app/profile`);
    }

    return (
      <>
        <Helmet title="Connexion" />
        <div className={styles.container}>
          <div className={styles.subcontainer}>
            <h1 style={{ marginBottom: 0 }}>
              <FaUserCircle />
            </h1>
            <h1 className="uppercase">Connexion</h1>
            <p className="text-red">{this.state.error}</p>
            <form
              id="loginForm"
              className="w-full"
              method="post"
              onSubmit={event => {
                this.handleSubmit(event);
              }}
            >
              <FormGroup>
                <TextField
                  label="Email *"
                  placeholder="Email"
                  InputProps={{
                    type: 'email',
                    required: true,
                    name: 'username',
                    onChange: this.handleUpdate,
                    value: this.state.username
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-5">
                <TextField
                  label="Password *"
                  placeholder="Password"
                  InputProps={{
                    type: 'password',
                    required: true,
                    name: 'password',
                    onChange: this.handleUpdate,
                    value: this.state.password
                  }}
                />
              </FormGroup>
            </form>
            <button form="loginForm" disabled={this.state.isLoading} type="submit">
              {this.state.isLoading ? (
                <img src={patientez} alt="Patientez" style={{ verticalAlign: 'top', marginBottom: 0 }} />
              ) : (
                <img src={connexion} alt="Se connecter" style={{ verticalAlign: 'top', marginBottom: 0 }} />
              )}
            </button>

            <div className="flex mt-10">
              <Link className="text-blue" to="/app/signup">
                Créer un nouveau compte
              </Link>
              <Link className="text-blue ml-5" to="/app/forgot-password">
                Mot de passe oublié?
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
