import React from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames/bind';
import styles from './profile.module.css';
import { Router, Link } from '@reach/router';
import { Helmet } from 'react-helmet';
import ProfileDetails from './profile-details';
import ProfileSubscription from './profile-subscription';
import ProfileCats from './profile-cats';

class NotFound extends React.Component {
  componentDidMount() {
    console.log('toto');
    navigate('/404');
  }
  render() {
    return null;
  }
}

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? styles.antiRotatedContainer : null
      };
    }}
  />
);

const ProfileApp = props => {
  const cx = classNames.bind(styles);
  const className = cx({
    [styles.myAccountContainer]: true,
    [styles.bgRed]: props.location.pathname === '/app/profile/cats' ? true : false,
    [styles.bgYellow]: props.location.pathname === '/app/profile' ? true : false,
    [styles.bgGreen]: props.location.pathname === '/app/profile/subscription' ? true : false
  });
  return (
    <div className={className}>
      <h1>Mon Compte</h1>
      <div className={styles.navContainer}>
        <nav>
          <NavLink to="./">
            <span>Détails</span>
          </NavLink>
          <NavLink to="subscription">
            <span>Abonnement</span>
          </NavLink>
          <NavLink to="cats">
            <span>Mon Chat</span>
          </NavLink>
        </nav>
      </div>
      {props.children}
    </div>
  );
};
class Profile extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <Helmet title="Mon Compte" />
        <Router>
          <ProfileApp path="/">
            <ProfileDetails path="/" />
            <ProfileSubscription path="subscription" />
            <ProfileCats path="cats" />
            <NotFound default />
          </ProfileApp>
        </Router>
      </>
    );
  }
}

export default Profile;
